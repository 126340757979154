<template>
  <div class="pt-10">
    <!-- <wizfit-gallery></wizfit-gallery> -->
    <v-container>
      <v-row>
        <v-col cols="8">
          <campaign-info-completed></campaign-info-completed>
        </v-col>
      </v-row>
      <v-row class="pt-4 pb-10">
        <v-col cols="8" class="pt-15">
          <v-row justify="center">
            <v-col cols="3">
              <div>
                <v-icon size="55" color="#27AE60"
                  >mdi-check-circle-outline</v-icon
                >
              </div>
            </v-col>
          </v-row>

          <v-row justify="center">
            <v-col cols="9">
              <div class="completedMessage text-center">
                This Campaign is successfully completed
              </div>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="3">
          <div>
            <img width="350px" src="@/assets/endImgNote.png" alt="" />
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
export default {
  name: "CampaignCompleted",
  components: {
    // WizfitGallery: () => import("../WizfitGallery/WizfitGallery"),
    // SchoolDetail: () => import("../SchoolDetaill/SchoolDetail"),
    CampaignInfoCompleted: () => import("./CampaignInfo/CampaignInfoCompleted"),
  },
  data() {
    return {};
  },
  computed: {},
  watch: {},
  methods: {},
  mounted() {},
};
</script>
<style scoped>
.completedMessage {
  font-family: "Roboto Slab";
  font-style: normal;
  font-weight: 500;
  font-size: 25px;
  line-height: 49px;
  /* identical to box height, or 136% */

  display: flex;
  align-items: center;
  text-align: center;

  /* Green 2 */

  color: #27ae60;
}
</style>
